import { Component, OnInit } from '@angular/core';
import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { from } from 'rxjs';
import { animate } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  currentYear: string;

  overviews = [
    {
      id: 1,
      toggled: false,
      icon: "Control3D.svg",
      title: "Control3D",
      description: "Steuerung von Messsonden in drei Raumdimensionen für physikalische Experimente. Mit einem Rastersondenmikroskop manipulieren wir einzelne Atome, die kleinsten Bausteine der Materie. VR-basierte und haptische Steuerung ermöglichen die direkte Kontrolle in drei Dimensionen, insbesondere auf der Nanoskala."
    },
    {
      id: 2,
      toggled: false,
      icon: "LabNet.svg",
      title: "LabNet",
      description: "Das vernetzte physikalische Labor. Mess- und Steuergeräte werden über verschiedene Schnittstellen mit dem Industriestandard OPC vernetzt und angesteuert. Geräte können dynamisch hinzugefügt und über ein Webinterface sowie in VR gesteuert werden. Messdaten werden automatisch gespeichert."
    },
    {
      id: 3,
      toggled: false,
      icon: "Analysis3D.svg",
      title: "Analysis3D",
      description: "Inspektion komplexer Daten. Mit spezifischen Algorithmen der Computergrafik wird eine wissenschaftsgetreue Darstellung von komplexen Daten realisiert. Die dritte Raumdimension ist direkt zugänglich und Beziehungen zwischen Daten einfach herstellbar."
    },
    {
      id: 4,
      toggled: false,
      icon: "Collaborate.svg",
      title: "Collaborate",
      description: "Zusammen arbeiten, gemeinsam messen, Expertenrat einholen. Benutzerinnen und Benutzer können sich mit Freigabelink in ein laufendes Experiment oder Datenanalyse einwählen."
    },
    {
      id: 5,
      toggled: false,
      icon: "Educate.svg",
      title: "Educate",
      description: "Große Geräte für kleinste Systeme. Mit der Positionierung eines 3D Druckers bilden wir zu Demonstrationszwecken die Funktion eines Rastersondenmikroskops nach"
    },
  ];


  constructor() {

   }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear().toString();
  }

  enterOverviewElement(overviewElementID: number) {
    let element = this.overviews.find(overviewElement => overviewElement.id == overviewElementID);
    if(!element) {
      console.log("element not found");
      return;
    }
    element.toggled = true;
    element.icon = element.icon.replace(/(\.[\w\d_-]+)$/i, '-white$1');

  }

  
  leaveOverviewElement(overviewElementID: number) {
    let element = this.overviews.find(overviewElement => overviewElement.id == overviewElementID);
    if(!element) {
      console.log("element not found");
      return;
    }
    element.toggled = false;
    element.icon = element.icon.replace('-white','');
  }

}
