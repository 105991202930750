<div id="wrapper">
  <div id="banner">
    <img src="/assets/imgs/VRnano-Laborumgebung-1920x800.jpg" class="banner-img" height="600px">
    <!--<div class="slideTitle">
     <h2>Im virtuellen Labor</h2> 
    </div>
    -->
  </div>
  <div class="info-text" id="info">
    <div class="info-text__title">
      <h2 class="info-text__title__center">Komplexe Laborgeräte <br> VR-basiert steuern</h2>
    </div>
    <div class="info-text__detail">
      <p class="info-text__detail__center p_small">Im Fokus von VRnano stehen Experimente der Nanowissenschaften,
        bei denen sich die entscheidenden Elemente auf Grund ihrer Größe oder
        physikochemischen Eigenschaften einer direkten Interaktion mit den
        Wissenschaftlerinnen und Wissenschaftlern entziehen.</p>
    </div>
  </div>
  <div class="goal">
    <div class="goal__list">
      <h3 class="goal__list__title" style="padding-bottom: 8%;">Projektziele</h3>
      <h5 class="goal__list__title blue">Steuerung</h5>
      <p class="goal__list__title p_smaller">Interaktion mit komplexen Experimenten mittels
        dreidimensionaler Gestensteuerung sowie durch flexible Benutzung
        von physikalischen Kontrolleinheiten in VR.</p>
      <h5 class="goal__list__title blue">Darstellung</h5>
      <p class="goal__list__title p_smaller">Die Echtzeitdarstellung von Messparametern und
        Messdaten sowie deren Archivierung als digitales Laborbuch.</p>
      <h5 class="goal__list__title blue">Erweiterung</h5>
      <p class="goal__list__title p_smaller">VRnano ist von Grund auf als Mehrbenutzer- und
        Remotesystem konzipiert. Zusammen mit der tiefen Integration
        der Laborkomponenten erweitert es die Möglichkeiten im Labor.</p>
    </div>
    <div class="goal__img">
      <img class="goal__img__file" src="/assets/imgs/Quanten-940x850.jpg">
    </div>
  </div>
  <div class="overview-title" style="background-color: white;">
    <h3>Die VRnano Komponenten</h3>
  </div>
  <div class="overview ">
    <div class="overview-element row" [ngClass]="{'overview-element-hovered': overview.toggled === true }" 
      (mouseenter)="enterOverviewElement(overview.id)" (mouseleave)="leaveOverviewElement(overview.id)" *ngFor="let overview of overviews">
      <div class="overview-icon">
        <img [src]="'../../assets/overview/' + overview.icon">
      </div>
      <div class="overview-text">
        <h2>
          {{overview.title}}
        </h2>
        <p id="toggle-text">
          {{overview.description}}
        </p>
      </div>
    </div>
  </div>
  <!--<div class="galerie">
    <div class="galerie__list">
      <h3 class="galerie__list__title blue">Nanowissenschaften</h3>
      <p class="galerie__list__item p_smaller">Die Nanowissenschaften sind Schlüssel- und Querschnittstechnologie sowie
        Treiber für zukunftsweisende Technologien der Metallverarbeitung über die Medizin- und Elektrotechnik bis hin
        zur Luft- und Raumfahrt. Der Transfer von VRnano in weitere Felder wie Biologie, Medizin, Chemie und
        Werkstoffwissenschaften sowie bspw. zum "Rapid-Prototyping" im Bereich des Nanomanufacturings ist elementarer
        Bestandteil der Projektplanung.</p>
    </div>
    <div class="galerie__list">
      <h3 class="galerie__list__title blue">Big Data</h3>
      <p class="galerie__list__item p_smaller">Eine Folge der Big-Data-Revolution der letzten Jahre ist das
        exponentielle Wachstum der Datenraten, Datenmengen und Datenkomplexität in fast allen Wirtschaftsbereichen.
        VRnano bietet durch den Einsatz von hardwarenahen und parallelisierten Rendering-Verfahren die Möglichkeit, um
        solch multidimensionale Datenräume in interaktiven Visualisierungssystemen darstellen zu können.</p>
    </div>
    <div class="galerie__list">
      <h3 class="galerie__list__title blue"> Forschung</h3>
      <p class="galerie__list__item p_smaller">VRnano trägt zur Schwellensenkung für Nachwuchswissenschaftler bei und
        ermöglicht optimale (Remote-)Betreuung bei gleichzeitiger Kostenreduktion. Neue wissenschaftliche Erkenntnisse
        wie bspw. aktualisierte Modellrechnungen der Moleküle können sehr einfach integriert werden und bieten damit
        stets die optimale Forschungsumgebung.</p>
    </div>

  </div>-->
  <div class="forschung" id="uns">
    <div class="forschung__img">
      <img class="forschung__img__file" src="/assets/imgs/Forschungslabore-steuern-700x700.jpg">
    </div>
    <div class="forschung__info">
      <h3 class="forschung__info__item" style="padding-bottom: 8%;">Forschungslabore aus der Ferne steuern</h3>
      <p class="forschung__info__item p_smaller" style="padding-bottom: 8%;">Neue Wege zur Steuerung von Laborgeräten
        geht das
        Verbundprojekt
        „KMU-innovativ - VRnano: Multimodale, bidirektionale und haptische
        Virtual-Reality-Steuerung hochkomplexer Laborgeräte der
        Nanowissenschaften“, bei dem Forscherinnen und Forscher der
        Nanowissenschaften in die Virtuelle Realität versetzt werden.
        VRnano wird vom Bundesministerium für Bildung und Forschung mit
        insgesamt 1,1 Millionen Euro gefördert. Beteiligt sind die Hochschule
        Osnabrück, die Universität Osnabrück und die Osnabrücker VR-Agentur
        mindQ GmbH.</p>
      <p class="forschung__info__item p_small" style="color:#03246C; padding-bottom: 20px;">
        <strong> News und Pressemitteilungen </strong>
      </p>
      <p class="forschung__info__item p_smaller" style="color:#03246C; ">
        BMBF </p>
      <p class="forschung__info__item p_smaller" style="padding-bottom: 20px;"><a
          href="https://www.interaktive-technologien.de/projekte/vrnano" style="color:#03246C; ">
          VRnano Effektive Interaktion im Nanometerbereich – Mit VR-Technologie hochkomplexe Laborgeräte intuitiv
          steuern</a>
      </p>
      <p class="forschung__info__item p_smaller" style="color:#03246C; ">
        Universität Osnabrück </p>
      <p class="forschung__info__item p_smaller" style="padding-bottom: 20px;"><a
          href="https://www.uni-osnabrueck.de/kommunikation/kommunikation-und-marketing-angebot-und-aufgaben/pressestelle/pressemeldung/news/osnabruecker-hochschulen-entwickeln-gemeinsam-mit-der-vr-agentur-mindq-gmbh-ein-virtual-reality-syste/?tx_news_pi1%5Bcontroller%5D=News&tx_news_pi1%5Baction%5D=detail&cHash=6fe871ab6467b681e85ec47aca219212"
          style="color:#03246C; ">
          Osnabrücker Hochschulen entwickeln gemeinsam mit der VR-Agentur mindQ GmbH ein Virtual Reality-System für die
          Laborarbeit in den Nanowissenschaften</a>
      </p>
      <p class="forschung__info__item p_smaller" style="color:#03246C;">
        Hochschule Osnabrück </p>
      <p class="forschung__info__item p_smaller" style="padding-bottom: 20px;"><a
          href="https://www.hs-osnabrueck.de/nachrichten/2021/01/forschungslabore-aus-der-ferne-steuern/"
          style="color:#03246C;">
          Forschungslabore aus der Ferne steuern</a>
      </p>
      <p class="forschung__info__item p_smaller" style="color:#03246C;">
        Wirtschaftsförderung Osnabrück GmbH (WFO) </p>
      <p class="forschung__info__item p_smaller" style="padding-bottom: 20px;"><a
          href="https://typisch-osnabrueck.de/project/forschungslabore-ferngesteuert/" style="color:#03246C;">
          Virtual Reality-System aus Osnabrück für die Laborarbeit in den Nanowissenschaften</a>
      </p>
      <p class="forschung__info__item p_smaller" style="color:#03246C;">
        mindQ GmbH & Co. KG
      </p>
      <p><a class="forschung__info__item p_smaller" href="https://www.linkedin.com/posts/mindq-gmbh-co-kg_bmbf-mittelstandskonferenz-activity-7133406687830405121-UPXC/?utm_source=share&utm_medium=member_desktop"
        style="color:#03246C;">
        BMBF-Mittelstandskonferenz 2023 in Berlin</a>
      </p>
    </div>
  </div>
  <div class="we">
    <div class="we__title">
      <h4 class="we__title__item blue">Ein Verbundprojekt in Zusammenarbeit mit</h4>
      <h4 class="we__title__item blue" style="padding-right: 50px;">Gefördert vom</h4>
    </div>
    <div class="we__logo">
      <a class="links we__logo__img2" href="https://www.uni-osnabrueck.de/startseite/">
        <img class="we__logo__img" src="/assets/logos/Logo-Uni-Hs.jpg" alt="">
      </a>
      <a class="links we__logo__img3" href="https://www.hs-osnabrueck.de/">
        <img class="we__logo__img" src="/assets/logos/Logo-Hochschule-Os.jpg" alt="">
      </a>
      <a class="links we__logo__img1" href="https://www.mindq.de/">
        <img class="we__logo__img" src="/assets/logos/Logo-mindQ.jpg" alt="">
      </a>
      <a class="links we__logo__img4" href="https://www.bmbf.de/">
        <img class="we__logo__img" src="/assets/logos/Logo-BMBF.jpg" alt="">
      </a>
    </div>
  </div>

  <div class="weMobile">
    <div class="we__title-m">
      <h4 class="we__title-m__item blue">Ein Verbundprojekt in Zusammenarbeit mit</h4>
      <div class="we__logo-m__a">
        <a class="links we__logo__img2" href="https://www.uni-osnabrueck.de/startseite/">
          <img class="we__logo__img" src="/assets/logos/Logo-Uni-Hs.jpg" alt="">
        </a>
        <a class="links we__logo__img3" href="https://www.hs-osnabrueck.de/">
          <img class="we__logo__img" src="/assets/logos/Logo-Hochschule-Os.jpg" alt="">
        </a>
        <a class="links we__logo__img1" href="https://www.mindq.de/">
          <img class="we__logo__img" src="/assets/logos/Logo-mindQ.jpg" alt="">
        </a>
      </div>
    </div>
    <div class="we__logo-m">
      <h4 class="we__title-m__item blue">Gefördert vom</h4>
      <div class="we__logo-m__b">
        <a class="links we__logo__img4" href="https://www.bmbf.de/">
          <img class="we__logo__img" src="/assets/logos/Logo-BMBF.jpg" alt="">
        </a>
      </div>
    </div>
  </div>
  <div class="footer" id="contact">
    <div class="contact__info">
      <div class="contact__info__logo"></div>
      <div class="contact__info__adr">
        <p class="p_smaller">mindQ GmbH & Co. KG, <br> Albert-Einstein-Strasse 1 <br> 49076 Osnabrück</p>
      </div>
      <div class="contact__info__nr">
        <p class="p_smaller">+49 541 20068305 <br> post@mindq.de</p>
      </div>
    </div>
    <div class="datenschutz">
      <p class="datenschutz__l p_smaller">© {{currentYear}} VRnano</p>
      <p class="datenschutz__r p_smaller"> <a href="https://www.mindq.de/datenschutz.html ">Datenschutz | </a><a href="https://www.mindq.de/impressum.html">Impressum</a></p>
    </div>
  </div>
</div>