<div id="header">
    <label for="nav__mobile-input" id="navOpener">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </label>

    <input hidden type="checkbox" name="" class="nav__input" id="nav__mobile-input">
    <label for="nav__mobile-input" class="nav__overlay"></label>
    <div class="nav__mobile">
        <label for="nav__mobile-input"><img class="nav__mobile-close" src="../assets/icons/cancel.svg" />
        </label>
            <ul class="nav__mobile-list">
                <li><a class="nav__mobilev-item" href="#info"><span>Über VRnano</span></a>
                </li>
                <li><a class="nav__mobilev-item" href="#uns"><span>Über uns</span></a>
                </li>
                <li><a class="nav__mobilev-item" href="#contact"><span>Kontakt</span></a></li>
                <!-- <li class="desktop"><a href="index-en.html" style="color: #34C9C6;">EN</a></li> -->
            </ul>
        
    </div>

    <div class="nav-bar">
        <img class="logo" src="../assets/logos/Logo-VRnano.png" height="42" alt="vrnano" title="logo">
        <div id="navigation">
            <ul class="nav-list">
                <li class="nav-item"><a href="#info"><span>Über VRnano</span></a>
                </li>
                <li class="nav-item"><a href="#uns"><span>Über uns</span></a>
                </li>
                <li class="nav-item"><a href="#contact"><span>Kontakt</span></a></li>
                <!-- <li class="desktop"><a href="index-en.html" style="color: #34C9C6;">EN</a></li> -->
            </ul>
        </div>
    </div>
</div>